/** @jsx jsx */

import React from 'react'
import { css, jsx } from '@emotion/core'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Bio } from '../components/bio'
import { Layout } from '../components/layout'

type Props = {
  data: {
    mdx: {
      [key: string]: string
    }
  }
  location: Location
  pageContext: PageContext
}

type PageContext = {
  next: Object
  previous: Object
}

const BlogPost: React.SFC<Props> = ({ data, location, pageContext }) => {
  const post = data.mdx
  const title = `${post.frontmatter.title} / Blog / Sumeru Chatterjee`
  const { previous, next } = pageContext

  return (
    <Layout location={location} headerTitle="blog" pageTitle={title}>
      <h1
        css={css`
          font-size: 1.75em;
          font-weight: 700;
          line-height: 1.25em;
          transition: all 0.5s ease-out;
        `}
      >
        {post.frontmatter.title}
      </h1>
      <p
        style={{
          display: `block`,
          marginTop: 20
        }}
      >
        {post.frontmatter.date}
      </p>
      <MDXRenderer>{post.body}</MDXRenderer>
      <hr
        style={{
          marginBottom: 20
        }}
      />
      <Bio />

      <ul
        style={{
          display: `flex`,
          flexWrap: `wrap`,
          justifyContent: `space-between`,
          listStyle: `none`,
          padding: 0,
          margin: 0
        }}
      >
        <li>
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title} →
            </Link>
          )}
        </li>
      </ul>
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
      body
    }
  }
`