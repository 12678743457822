import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

export const Bio: React.SFC<{}> = () => {
  const data = useStaticQuery(bioQuery)
  const { author, social } = data.site.siteMetadata

  return (
    <div
      style={{
        display: `flex`,
        marginBottom: 20
      }}
    >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        style={{
          marginRight: 20,
          marginBottom: 0,
          minWidth: 50,
          borderRadius: `100%`
        }}
      />
      <p>
        Written by <strong>{author}</strong> who lives in Cali, Colombia and Amsterdam, Netherlands.
        {` `}
        <a
          href={`https://twitter.com/${social.twitter}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Follow him on Twitter.
        </a>
      </p>
    </div>
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/avatar.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
          instagram
          linkedin
          github
        }
      }
    }
  }
`
